import { Grid, Button } from '@material-ui/core';
import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LanguageIcon from './img/languageIcon.svg';
import ContactIcon from './img/contactIcon.svg';
import { topHeaderStyles } from './TopHeaderStyles';
import LeadGenerationContext from '../../context/LeadGenerationContext';
import OrientalImage from '../../images/oriental-bank.svg';
import { dgiMapping } from '../../services/dgiMapping';
import {
  HOME_VI,
  ESP,
  ENG,
  PERSONAL_INFO,
  FINANCE_INFO,
  LANGUAGE_ICON,
  CHANGE_LANGUAGE,
  USER_LOGO,
  HELLO,
  ORIENTAL_LOGO,
  DGIADDITIONAL_INFO,
  BUSINESS_COMP,
  BUSINESS_ACCOUNT_INFO,
  BUSINESS_SURI,
  BUSINESS_ADDITIONAL_INFO,
  BUSINESS_COMPLIANCE_INFO,
  BUSINESS_INFO,
  FUNDING_INFO,
  PRODUCT_INFO,
  COMPLIANCE_INFO,
  ADDRESS_INFO,
  CONTACT_INFO,
  ES,
} from '../../constants';
import { businessMapping } from '../../services/businessMapping';
import { productTypeMapping } from '../../services/productTypeMapping';

const useStyles = topHeaderStyles();

export default function TopHeader(props) {
  const styledClasses = useStyles();
  const { setLanguage, firstName } = props;
  const [languageString, setLanguageString] = useState(ESP);
  const { t } = useTranslation();
  const leadGenerationContext = useContext(LeadGenerationContext);

  useEffect(() => {
    if (firstName?.length === 0) {
      leadGenerationContext.setUserName('Guest');
    }
    if (firstName?.length > 0) {
      leadGenerationContext.setUserName(`${firstName}`);
    }
  }, [firstName]);

  useEffect(() => {
    if (leadGenerationContext.language === ES || leadGenerationContext.language === ESP) {
      setLanguageString(ENG);
    } else {
      setLanguageString(ESP);
    }
  }, [leadGenerationContext.language]);
  const setHeaderLanguage = () => {
    leadGenerationContext.setSuriLang(true);
    if (languageString === ESP) {
      setLanguageString(ENG);
      setLanguage(ESP);
    } else {
      setLanguageString(ESP);
      setLanguage(ENG);
    }
  };
  const setAddressInfoPage = () => {
    if (dgiMapping(leadGenerationContext.productName)) {
      leadGenerationContext.setIsScreenThreeDirty(true);
      leadGenerationContext.setIsTrustInfoDirty(true);
      leadGenerationContext.setIsFinanceInfoDirty(true);
      leadGenerationContext.setIsFundingScreenDirty(true);
    } else {
      leadGenerationContext.setIsScreenThreeDirty(true);
      leadGenerationContext.setIsScreenFourDirty(true);
      leadGenerationContext.setIsScreenFiveDirty(true);
      leadGenerationContext.setIsFundingScreenDirty(true);
    }
  };
  const currentPageCondition = () => {
    if (leadGenerationContext.currentPage === BUSINESS_COMPLIANCE_INFO) {
      leadGenerationContext.setIsBusinessComplianceDirty(true);
      leadGenerationContext.setIsBusinessSuriDirty(true);
      leadGenerationContext.setIsBusinessAdditionalInfoDirty(true);
      leadGenerationContext.setIsBusinessAccountInfoDirty(true);
      leadGenerationContext.setIsBusinessCompDirty(true);
      leadGenerationContext.setIsDocChecklistDirty(true);
      leadGenerationContext.setIsCommercialProductUIDirty(true);
    }
    if (leadGenerationContext.currentPage === BUSINESS_ADDITIONAL_INFO) {
      leadGenerationContext.setIsBusinessAdditionalInfoDirty(true);
      leadGenerationContext.setIsBusinessAccountInfoDirty(true);
      leadGenerationContext.setIsBusinessCompDirty(true);
      leadGenerationContext.setIsDocChecklistDirty(true);
      leadGenerationContext.setIsCommercialProductUIDirty(true);
    }
    if (leadGenerationContext.currentPage === BUSINESS_SURI) {
      leadGenerationContext.setIsBusinessSuriDirty(true);
      leadGenerationContext.setIsBusinessAdditionalInfoDirty(true);
      leadGenerationContext.setIsBusinessAccountInfoDirty(true);
      leadGenerationContext.setIsBusinessCompDirty(true);
      leadGenerationContext.setIsDocChecklistDirty(true);
      leadGenerationContext.setIsCommercialProductUIDirty(true);
    }
    if (leadGenerationContext.currentPage === BUSINESS_ACCOUNT_INFO) {
      leadGenerationContext.setIsBusinessAccountInfoDirty(true);
      leadGenerationContext.setIsBusinessCompDirty(true);
      leadGenerationContext.setIsDocChecklistDirty(true);
      leadGenerationContext.setIsCommercialProductUIDirty(true);
    }
    if (leadGenerationContext.currentPage === BUSINESS_COMP) {
      leadGenerationContext.setIsBusinessCompDirty(true);
      leadGenerationContext.setIsDocChecklistDirty(true);
      leadGenerationContext.setIsCommercialProductUIDirty(true);
    }
    if (leadGenerationContext.currentPage === 'document-checklist') {
      leadGenerationContext.setIsDocChecklistDirty(true);
      leadGenerationContext.setIsCommercialProductUIDirty(true);
    }
    if (leadGenerationContext.currentPage === FINANCE_INFO) {
      leadGenerationContext.setIsFinanceInfoDirty(true);
      leadGenerationContext.setIsFundingScreenDirty(true);
    }
    if (leadGenerationContext.currentPage === DGIADDITIONAL_INFO) {
      leadGenerationContext.setIsTrustInfoDirty(true);
      leadGenerationContext.setIsFinanceInfoDirty(true);
      leadGenerationContext.setIsFundingScreenDirty(true);
    }
  };
  const sendLanguage = () => {
    setHeaderLanguage();
    if (leadGenerationContext.currentPage === CONTACT_INFO) {
      leadGenerationContext.setIsScreenOneDirty(true);
      leadGenerationContext.setIsScreenTwoDirty(true);
      leadGenerationContext.setIsScreenThreeDirty(true);
      leadGenerationContext.setIsScreenFourDirty(true);
      leadGenerationContext.setIsScreenFiveDirty(true);
      leadGenerationContext.setIsFundingScreenDirty(true);
    }
    if (leadGenerationContext.currentPage === PERSONAL_INFO) {
      leadGenerationContext.setIsScreenTwoDirty(true);
      leadGenerationContext.setIsScreenThreeDirty(true);
      leadGenerationContext.setIsScreenFourDirty(true);
      leadGenerationContext.setIsScreenFiveDirty(true);
      leadGenerationContext.setIsFundingScreenDirty(true);
    }
    if (leadGenerationContext.currentPage === ADDRESS_INFO) {
      setAddressInfoPage();
    }
    if (leadGenerationContext.currentPage === COMPLIANCE_INFO) {
      leadGenerationContext.setIsScreenFourDirty(true);
      leadGenerationContext.setIsScreenFiveDirty(true);
      leadGenerationContext.setIsFundingScreenDirty(true);
    }
    if (leadGenerationContext.currentPage === PRODUCT_INFO) {
      if (
        businessMapping(leadGenerationContext.productName) &&
        (productTypeMapping(leadGenerationContext.productType) === 'Checking Account' ||
          productTypeMapping(leadGenerationContext.productType) === 'Savings Account')
      ) {
        leadGenerationContext.setIsCommercialProductUIDirty(true);
      }
      leadGenerationContext.setIsFundingScreenDirty(true);
      leadGenerationContext.setIsScreenFiveDirty(true);
    }
    if (leadGenerationContext.currentPage === FUNDING_INFO) {
      leadGenerationContext.setIsFundingScreenDirty(true);
    }
    if (leadGenerationContext.currentPage === BUSINESS_INFO) {
      leadGenerationContext.setIsBusinessContactInfoDirty(true);
      leadGenerationContext.setIsBusinessComplianceDirty(true);
      leadGenerationContext.setIsBusinessSuriDirty(true);
      leadGenerationContext.setIsBusinessAdditionalInfoDirty(true);
      leadGenerationContext.setIsBusinessAccountInfoDirty(true);
      leadGenerationContext.setIsBusinessCompDirty(true);
      leadGenerationContext.setIsDocChecklistDirty(true);
      leadGenerationContext.setIsCommercialProductUIDirty(true);
    }
    currentPageCondition();
  };

  return (
    <Grid container className={styledClasses.top_header}>
      <div className={styledClasses.leftHeaderSection}>
        <img
          src={OrientalImage}
          className={styledClasses.oriental}
          tabIndex="0"
          alt={ORIENTAL_LOGO}
          aria-label="Oriental Logo"
        />

        <div className={styledClasses.right_mobile_header}>
          {leadGenerationContext.location !== HOME_VI ? (
            <Button
              disableFocusRipple
              data-testid="topHeader_btnid"
              onClick={sendLanguage}
              disabled={leadGenerationContext.location === HOME_VI}
              className={styledClasses.lang_change_button}
              role="button"
              aria-label={`${t(CHANGE_LANGUAGE, {
                defaultValue: CHANGE_LANGUAGE,
              })}`}
            >
              <img
                src={LanguageIcon}
                alt={LANGUAGE_ICON}
                aria-label="Language Icon"
                className={styledClasses.language_icon}
              />
              {leadGenerationContext.location === HOME_VI && ENG}
              {languageString === ENG && leadGenerationContext.location !== HOME_VI && ENG}
              {languageString === ESP && leadGenerationContext.location !== HOME_VI && ESP}
            </Button>
          ) : (
            ''
          )}
        </div>
      </div>
      <div
        className={styledClasses.username_block}
        aria-label={`${t(HELLO, { defaultValue: HELLO })} ${t(leadGenerationContext.username, {
          defaultValue: leadGenerationContext.username,
        })}`}
        tabIndex="0"
      >
        {`${t(HELLO, { defaultValue: HELLO })} ${t(leadGenerationContext.username, {
          defaultValue: leadGenerationContext.username,
        })}`}
        <img
          src={ContactIcon}
          alt={USER_LOGO}
          aria-label="User Logo"
          className={styledClasses.profile_icon}
        />
      </div>
    </Grid>
  );
}

TopHeader.propTypes = {
  firstName: PropTypes.string,
  setLanguage: PropTypes.func,
};
