/* eslint-disable max-lines */
// Spanish Translations
export const espTranslation = {
  'We cannot complete your application.': 'No podemos completar la solicitud.',
  'You are currently not eligible to open this account due to information on your profile.':
    'Basado en la información de tu perfil,actualmente no eres elegible para abrir esta cuenta.',
  'Please provide additional details': 'Por favor, proporcione más detalles',
  'Do you have an Active SURI Merchant Number?': '¿Tienes un número de comerciante SURI activo?',
  'Document Checklist': 'Lista de Documentos',
  '(Upload address proof)': '(Sube prueba de dirección)',
  'Oops we hit a snag!': '¡Ups, hubo un error!',
  'Enter your SURI Merchant Number details and search:': 'Ingrese tu número de comerciante SURI:',
  'Use the edit icon to provide the correct address information. Upload address proof!':
    'Presiona el botón de editar para proveer la dirección correcta. ¡Sube tu comprobante de dirección!',
  'Enter New Address': 'Ingrese nueva dirección',
  'An active SURI Merchant Number is required to continue.':
    'Se requiere un número de comerciante SURI activo para continuar.',
  'For You': 'Para ti',
  Hello: 'Hola',
  Guest: 'Invitado',
  'First Name': 'Nombre',
  'Middle Initial': 'Inicial',
  'Last Name': 'Apellido Paterno',
  'Second Last Name': 'Apellido Materno',
  Gender: 'Género',
  'Alternate Phone': 'Teléfono alterno',
  'Date of Birth': 'Fecha de Nacimiento',
  'date of birth': 'fecha de nacimiento',
  'Date of birth': 'Fecha de nacimiento',
  'last name': 'apellidos',
  ' and ': ' y ',
  'Your ': 'Tu ',
  ' has been updated as per your uploaded Id.':
    ' han sido actualizados según la identificación provista.',
  'Email Address': 'Correo electrónico',
  'Social Security Number': 'Número de Seguro Social',
  'Social security number': 'Número de seguro social',
  'Enter first name': 'Ingresa nombre',
  'Enter First Name': 'Ingresa nombre',
  'Enter middle initial': 'Ingresa inicial',
  'Enter Middle Initial': 'Ingresa inicial',
  'Enter last name': 'Ingresa apellido paterno',
  'Enter Last Name': 'Ingresa apellido paterno',
  'Enter second last name': 'Ingresa apellido',
  'Enter Second Last Name': 'Ingresa apellido',
  'Enter phone number': 'Ingresa teléfono',
  'Enter Phone Number': 'Ingresa teléfono',
  'Enter mobile number': 'Ingresa número celular',
  'Enter Mobile Number': 'Ingresa número celular',
  'Enter email address': 'Ingresa correo electrónico',
  'Enter Email Address': 'Ingresa correo electrónico',
  'Mobile Number': 'Número celular',
  Male: 'Masculino',
  Female: 'Femenino',
  '* Invalid Format': '* Formato Inválido',
  '* Invalid Email Domain': '* Dominio de correo eletrónico no válido',
  '* Mandatory Field': '* Campo Requerido',
  Back: 'Regresar',
  Continue: 'Continuar',
  'Home Address': 'Dirección Residencial',
  'Address Line 1': 'Dirección Línea 1',
  'Address Line 2': 'Dirección Línea 2',
  State: 'Estado',
  'Select State': 'Seleccionar Estado',
  City: 'Ciudad',
  'Zip Code': 'Código Postal',
  'Mailing address same as home address': 'Dirección postal igual a la residencial',
  'Enter address 1': 'Ingresa dirección 1',
  'Enter address 2': 'Ingresa dirección 2',
  'Enter Zip Code': 'Ingresa código postal',
  Submit: 'Enviar',
  'Thank you for choosing Oriental': '¡Gracias por escoger a Oriental',
  'Your information was sent successfully. We will contact you in the next 24 to 48 hours.':
    'Tu informacion fue enviada exitosamente. Nos comunicaremos contigo en las próximas 24 a 48 horas.',
  'Your information was sent successfully. We will contact you in the next 24 hours to complete the account opening process.':
    'Tu informacion fue enviada exitosamente. Nos comunicaremos contigo en las próximas 24 horas para completar el proceso de apertura de cuenta.',
  'Your information was sent successfully. We will contact you in the next 48 hours to complete the account opening process.':
    'Tu informacion fue enviada exitosamente. Nos comunicaremos contigo en las próximas 48 horas para completar el proceso de apertura de cuenta.',
  'Return to orientalbank.com': 'Regresar a orientalbank.com',
  YYYY: 'AAAA',
  DD: 'DD',
  MMM: 'MMM',
  'Enter Unit Number and Street': 'Ingresa Número y Calle',
  '* Zipcode should have 5 digits': '* El código postal debe tener 5 dígitos',
  '* Area Code is Mandatory': '* El código de área es obligatorio',
  '* Area Code should have 3 digits': '* El código de área debe tener 3 dígitos',
  USA: 'EU',
  VI: 'IV',
  'Libre Account': 'Cuenta Libre',
  'Cuenta Libre': 'Cuenta Libre',
  // 'Progresa Account': 'Cuenta Progresa',
  // 'Cuenta Progresa': 'Cuenta Progresa',
  'Ahorra Account': 'Cuenta Ahorra',
  'Cuenta Ahorra': 'Cuenta Ahorra',
  'ABC Account': 'Cuenta ABC',
  'Cuenta ABC': 'Cuenta ABC',
  'Ahorro Club Account': 'Cuenta Ahorro Club',
  'Cuenta Ahorro Club': 'Cuenta Ahorro Club',
  'Certificate of Deposit': 'Certificado de Deposito',
  'Certificado de Deposito': 'Certificado de Deposito',
  'DGI Balanced Fund IRA': 'DGI Balanced Fund IRA',
  'CD IRA': 'CD IRA',
  'IRA Facil': 'IRA Fácil',
  'Educational IRA': 'IRA Educativa',
  'IRA Educativa': 'IRA Educativa',
  'Annuity IRA': 'Annuity IRA',
  'DGI Contribution': 'DGI Aportaciones',
  'DGI Aportaciones': 'DGI Aportaciones',
  'Enter social security number': 'Ingresa el número de seguro social',
  '* You must be 18 years or older to open this account':
    '* Debes tener 18 años o más para abrir esta cuenta',
  '* You must be 21 years or older to open this account':
    '* Debes tener 21 años o más para abrir esta cuenta',
  '* Social Security Number should have 9 digits':
    '* El número de seguro social debe tener 9 dígitos',
  Upload: 'Subir',
  'Thank you': 'Gracias por proveer su información.',
  Instructions:
    'Por favor provee una imágen de tu licencia de conducir o pasaporte. Sigue las instrucciones de abajo para continuar el proceso de verificación en tu teléfono celular.<br/><br/>1. Abre la cámara o lector de QR en tu teléfono celular<br/>2. Sostén el dispositivo para que el Código de QR aparezca en la pantalla<br/>3. Presiona la notificación para abrir el enlace de verificación <br/><br/>',
  IRA: 'IRA',
  Footer: 'Oriental. Derechos Reservados. Miembro FDIC.',
  'Oriental. All Rights Reserved.': 'Oriental. Derechos Reservados.',
  '* Unknown Email': '* Email Desconocido',
  '* Email Syntax Error': '* Error Syntax de Email',
  '* Email Domain Not Found': '* Dominio de Email no fue encontrado',
  '* Email Server Not Found': '* Servidor de Email no fue encontrado',
  '* Invalid Email': '* Email inválido',
  '* Invalid Domain': '* Dominio Invalido',
  '* Unavailable Domain': '* Dominio No Disponible',
  'Aria Label Preferred Contact Time':
    'Horario de preferencia de la llamada Seleccione el botón del menú desplegable Horario de preferencia de la llamada',
  'Aria Label Gender': 'Género Requerido Seleccione el botón del menú desplegable Género',
  'Aria Label DOB day':
    'Fecha de Nacimiento Requerido Seleccione el mes en el menú desplegable Fecha de Nacimiento Requerido',
  'Aria Label DOB Month': 'Seleccione el mes en el menú desplegable Fecha de Nacimiento Requerido',
  'Aria Label DOB Year': 'Seleccione el año en el menú desplegable Fecha de Nacimiento Requerido',
  'Area Code': 'Codigo de AREA',
  'Aria Label Country': 'País Requerido Elija el botón del menú desplegable de su país',
  'Aria Label State': 'Estado Requerido Elija el botón del menú desplegable de su estado',
  'Aria Label City': 'Ciudad Requerido Elija el botón del menú desplegable de su ciudad',
  Required: 'Requerido',
  PreviewConsent:
    'La información sensitiva será encriptada, registrada y custodiada en nuestros sistemas. Debes proveer una identificación primaria, válida y vigente para confirmar tu información e identidad. ID aceptados son: licencia de conducir, pasaporte y tarjeta de pasaporte.*Capturas de pantallas de ID digitales no están permitidas.',
  lowerIdInstructions: 'Tome una foto de su identificación ahora',
  lowerFaceInstructions: 'Toma un selfi de tu rostro sin usar gafas, gorras, etc.',
  upperFailure: 'Intentar otra vez',
  verifyFail: 'Lo siento por los inconvenientes ocasionados',
  cameraButton: 'Toma una foto',
  'Provide Consent': 'Proveer consentimiento',
  FrontId: 'ID',
  Face: 'Selfi',
  Done: 'Listo',
  DoneId: 'Completado',
  'Enter address details': 'Ingresa los detalles de la dirección',
  'Enter Address Details': 'Ingresa los detalles de la dirección',
  'Enter Home Address Details': 'Ingresa dirección física',
  DefaultCountryPR: 'Debes ser residente de Puerto Rico para abrir esta cuenta.',
  DefaultCountryVI: 'Debe ser residente de Virgin Islands para abrir esta cuenta.',
  surveyContinue: 'Continuar',
  'Upload Home Address Proof': 'Sube una prueba de dirección física',
  Select: 'Seleccionar',
  'Select ': 'Seleccionar ',
  'Your Account': 'Tu Cuenta',
  'Your Information': 'Tu Información',
  'Upload ID or Passport': 'Subir ID o pasaporte',
  'Upload ID or Passport Required': 'Subir ID o pasaporte Requerido',

  '* Mobile number should have 7 digits': '* El número de teléfono celular debe tener 7 dígitos',
  'Error Try Again': 'Intente de Nuevo',
  'Select...': 'Seleccionar...',
  'Date Picker': 'Selector de fechas',
  Jan: 'Ene',
  Feb: 'Feb',
  Mar: 'Mar',
  Apr: 'Abr',
  May: 'May',
  Jun: 'Jun',
  Jul: 'Jul',
  Aug: 'Ago',
  Sep: 'Sep',
  Oct: 'Oct',
  Nov: 'Nov',
  Dec: 'Dic',
  'Account Opening Text DGICont':
    'Nuestro formulario en línea nos ayuda a conocer de ti y tus necesidades. Una vez completado, te contactaremos para completar tu solicitud.',
  ProductModalTextOne: 'En este momento no podemos completar la solicitud.',
  ProductModalTextTwo:
    'Por política de Oriental Bank, en estos momentos no  podemos establecer una relación con personas políticamente expuestas, sus familiares y sus socios cercanos.',
  'Upload Certification': 'Subir Certificación',
  'Account Opening Text':
    'Nuestro formulario en línea nos ayuda a conocer de ti y tus necesidades. Una vez completado, te contactaremos para comprobar tu identidad y abrir la cuenta.',
  'Let’s start!': '¡Comencemos!',
  'Where can we contact you?': '¿Dónde te podemos contactar?',
  'As per Oriental Bank policy we are currently unable to establish a relationship due to the selected business type.':
    'En este momento no ofrecemos cuentas bancarias para el tipo de negocio seleccionado.',
  'We currently do not offer bank products for the selected business type.':
    'En este momento no ofrecemos cuentas bancarias para el tipo de negocio seleccionado.',
  Mobile: 'Celular',
  'What is your home address?': '¿Cuál es tu dirección física?',
  'Do you have a different mailing address?': '¿Tienes una dirección postal distinta?',
  ConsentHelperText:
    'Favor de leer las siguientes divulgaciones y documentos, contienen información importante relacionada a tu cuenta.',
  ConsentHelperTextProg:
    'Antes de realizar esta transacción, debes entender tus derechos y responsabilidades y brindar tu consentimiento.',
  'Employment Status': 'Información de Empleo',
  'Employment Status Prog': '¿Cuál es tu situación laboral?',
  'Select Employment Status': 'Seleccionar Información de Empleo',
  Employed: 'Empleado',
  'Not Employed': 'No Empleado',
  Retired: 'Retirado',
  Employer: 'Patrono',
  EmployerProg: 'Empleador',
  'Enter Employer Name': 'Ingresa el nombre del patrono',
  Occupation: 'Ocupación',
  OccupationProg: 'Profesión',
  'Annual Income': 'Ingreso anual',
  'Select Annual Income': 'Seleccione su ingreso anual',
  'Less than': 'Menos que',
  'or More': 'o más',
  Reason: 'Razón',
  Student: 'Estudiante',
  'No Previous Employer': 'Sin Patrono Previo',
  'Not Currently Employed': 'Desempleado',
  'Previous Employer': 'Patrono Anterior',
  'Enter Previous Employer': 'Ingrese el empleador anterior',
  'Source of Current Income': 'Fuente de Ingresos',
  'Enter Source of Current Income': 'Ingresa la fuente de ingresos actuales',
  'Your Consent': 'Tu Consentimiento',
  'Interest Withholding Setup': 'Selección de Retención sobre Intereses',
  'Interest Withholding Setup Prog': 'Retención de Intereses',
  WithholdText:
    'Si deseas que Oriental Bank retenga intereses de tu cuenta de individuo, selecciona la siguiente opción.',
  WithholdTextProg:
    '¿Deseas que Oriental Bank retenga los intereses de esta cuenta? De ser asi, seleciona la opción incluida abajo',
  'Otherwise, please DO NOT check below': 'De otro modo, NO selecciones la opción de abajo.',
  'What is your Employment Status?': 'Tu información de empleo',
  'School Name': 'Institución Educativa',
  'Enter School Name': 'Ingresa el nombre de la escuela',
  'Enter your occupation details': 'Ingresa los detalles de tu ocupación',
  '* Invalid address': '* Dirección no válida',
  'Please Select': 'Selecciona',
  'Please select a reason': 'Selecciona una razón',
  Close: 'Cerrar',
  close: 'cerrar',
  '* Invalid Employer Name': '* Nombre del empleador no válido',
  '* Invalid School Name': '* Nombre de la escuela no válido',
  '* Invalid Previous Employer': '* Empleador anterior no válido',
  '* Invalid Source of Current Income': '* Fuente no válida de ingresos actuales',
  'Sorry we hit a snag. Please try submitting your application later.':
    'Lo sentimos, no podemos completar tu solicitud. Intenta nuevamente más tarde.',
  'Mailing Address Tooltip':
    'Dirección donde recibirás tu tarjeta de débito y otra correspondencia de parte del banco.',
  'Question Header Tooltip': 'No selecciones si no te aplica',
  'Politically Exposed Tooltip': `El término expuesto al ambiente político refiere generalmente a una figura política o pasado político extranjero, su familia inmediata y sus socios cercanos.`,
  'Act22 Tooltip': `Ley Núm. 22 de 2012, La "Ley de Inversionistas Individuales" provee exenciones contributivas a las personas elegibles que residan en Puerto Rico. Los beneficios de la Ley Núm. 22 solo están disponibles para los residentes bona fide de Puerto Rico que no fueron residentes bona fide de Puerto Rico durante el período de 6 años anterior a la promulgación de la Ley el 12 de enero de 2012.`,
  'More Info': 'Más información',
  Uploading: 'Subiendo',
  'Employment Status Aria Label':
    'Información de Empleo Requerido Seleccione el botón del menú desplegable Información de Empleo',
  'Annual Income Aria Label':
    'Ingreso Anual requeridos Seleccione el botón del menú desplegable Ingreso Anual',
  'Reason Aria Label': 'Razón requerido Seleccione el botón del menú desplegable Razón',
  'Change Language': 'Cambiar idioma',
  'Required Select dropdown menu button': 'Requerido Seleccionar el botón del menú desplegable',
  'ACHTransaction Tooltip':
    'Considera depósitos de nómina, pensiones, seguro social, etc. y pagos directos como pagos de auto, hipoteca, etc',
  'Mailing Address Info': 'Información de la dirección postal',
  upperIdInstructions: `<span class='vouched_idText' style="margin:0">Toma una foto de una identificación (no expirada) emitida por el Gobierno de PR, EE.UU. o USVI, </span><span class='vouched_idText' style="margin:0">(ej.: licencia de conducir, pasaporte).</span>`,
  retryMessage: 'No logramos reconocer la identificación.',
  'Your session has timed out.': 'Tu sesión ha expirado.',
  'Restart my application': 'Reiniciar mi solicitud',
  SessionModalTextLineTwo:
    'Para proteger tu información personal, borramos los datos entrados después de 15 minutos de inactividad. Puedes reiniciar tu solicitud en línea en cualquier momento.',
  InactivityTexLineOne:
    'Tu sesión expirara en 2 minutos debido a inactividad. ¿Deseas continuar con tu solicitud?',
  qrDesktopInstructions: 'Puedes {qrDesktopLink} si ésta tiene una cámara de alta resolución.',
  qrDesktopLink: 'completar la verificación en tu computadora',
  cameraFaceButton: 'Toma un Selfi',
  lowestFaceInstructions: `<span class="vouched_lowestFaceInstructions"><div class="vouched_faceText"><ul style="text-align: left; margin: 0 30px; padding: 0"><li class="vouched_faceText">No puede ser una foto de un retrato, salir otras personas y tu rostro debe estar completamente visible.</li></ul></div></span>`,
  '* Alternate phone number should not be same as mobile number':
    '* El numero de telefono alterno no debe ser el mismo que el número de teléfono celular',
  'Country/Region': 'País/Región',
  'Click to upload again.': 'Haz clic para subir de nuevo.',
  'Loading ...': 'Cargando ...',
  SelfieCameraButton: 'Sube una foto reciente',
  PreviewAddressConsent:
    'De no estar tu dirección física en tu ID, evidencia de la misma es requerida para poder abrir tu cuenta. De ser así, necesitas proveer una factura de utilidades (AEE, AAA, etc.) con menos de 60 días de vigencia con tu nombre y dirección.',
  Vouching: 'Validando',
  'Address Description Text':
    'Validar tu dirección es un paso importante para la confirmación de tu identidad. Revisa la información provista abajo y sube una prueba de dirección si tu dirección residencial actual es diferente a la de tu ID.',
  'Address Description Text Prog':
    'Validar tu dirección es un paso importante para la confirmación de tu identidad. Revisa la información provista abajo y sube una confirmación de dirección si tu dirección residencial actual es diferente a la de tu ID.',
  'Personal Info Text':
    'Sube tu licencia de conducir o pasaporte primer y nosotros completaremos la solicitud usando tu información.',
  'Personal Info Text Prog':
    'Sube tu ID con foto y nosotros completaremos la solicitud usando tu información.',
  'Id Description Text': '¡Ahorra tiempo! Sube tu ID y evita completar tu información manualmente.',
  'ID description Sub Text': 'Tu información será encriptada y protegida en nuestros sistemas.',
  'More about you!': 'Más sobre ti',
  'More About You': 'Más sobre ti',
  'Preferred Contact Time': 'Horario de preferencia de la llamada',
  'Preferred Time': 'Horario de preferencia de la llamada',
  '8:00 AM to 12:00 PM': '8:00 AM a 12:00 PM',
  '12:00 PM to 6:00 PM': '12:00 PM a 6:00 PM',
  ContactDisclaimer:
    'Al oprimir continuar autorizas a Oriental Bank a comunicarse contigo por medio de llamada, mensaje de texto y correo electrónico.',
  '* Valid Phone': '* Teléfono válido',
  '* 7-Digit Match': '* Coincidencia de 7 dígitos',
  '* Corrected Area Code': '* Código de área corregido',
  '* Updated Area Code': '* Código de área actualizado',
  '* Cellular Line': '* Línea celular',
  '* Land Line': '* Línea terrestre',
  '* VOIP Line': '* Línea VOIP',
  '* Residential Number': '* Numero residencial',
  '* Business Number': '* Numero de negocios',
  '* SOHO Number': '* Número SOHO',
  '* Invalid Phone': '* Teléfono inválido',
  '* Blank Phone': '* Teléfono en blanco',
  '* Bad Phone': '* Mal teléfono',
  '* Multiple Match': '* Partida múltiple',
  '* Bad Prefix/Prefix +1': '* Prefijo incorrecto/Prefijo +1',
  '* Please enter a valid phone number': '* Por favor ingresa un número de teléfono válido',
  '* Wrong country code': '* Código de país erróneo',
  'Self Employed': 'Empleado por cuenta propia',
  'Name of your Business': 'Nombre de tu Negocio',
  'Compliance Page Text':
    'Antes de poder abrir tu cuenta, hay información adicional sobre ti que debemos conocer.',
  'Compliance Page Text Prog':
    'Antes de abrir tu cuenta, necesitamos conocer información adicional sobre ti.',
  'Product Page Text':
    'Revisa las preguntas a continuación sobre la cuenta seleccionada y ayúdanos a entender el propósito. Responde solo si corresponde.',
  'Product Page Text Prog':
    'Revisa las preguntas a continuación sobre la cuenta que seleccionaste y ayúdanos a comprender el propósito. Responde solo si corresponde.',
  'Expired ID': '* ID Vencido',
  'Invalid ID': '* ID Inválido',
  'Funding Screen Description':
    'Escoge como prefieres depositar los fondos en tu cuenta. Procesaremos tu depósito en 24 a 72 horas.',
  'Funding Screen Description Prog':
    'Utiliza las siguientes secciones para elegir cómo prefieres depositar fondos en tu cuenta.',
  'How would you like to make your first deposit ?': '¿Cómo te gustaría hacer tu primer depósito?',
  'Deposit Amount': 'Cantidad del Depósito',
  'Type of Account': 'Tipo de Cuenta',
  'Account Number': 'Número de Cuenta',
  'Enter account number': 'Entra tu número de Cuenta',
  Bank: 'Banco',
  'Transit Number': 'Número de Tránsito',
  'Enter Route & Transit Number': 'Entra número de Ruta y Tránsito',
  'Oriental Account': 'Cuenta de Oriental',
  'Another Bank Account': 'Otra Cuenta Bancaria',
  'Aria Label Type Of Account':
    'Tipo de cuenta Requerido Seleccione el botón del menú desplegable Tipo de cuenta',
  'Aria Label Account Number':
    'Número de Cuenta Requerido Seleccione el botón del menú desplegable Número de Cuenta',
  'Aria Label Bank': 'Banco Requerido Seleccione el botón del menú desplegable Banco',
  'Card Number': 'Número de tarjeta',
  'Enter card number': 'Ingrese el número de tarjeta',
  '* Minimum deposit required is $': '* Deposito mínimo requerido es $',
  'Debit Card': 'Tarjeta de débito',
  'Account transfer or check': 'Transferencia de cuenta o cheque',
  'Expiry Date': 'Fecha de caducidad',
  'Upload a void check or Bank Statement in order to confirm your account.':
    'Suba cheque cancelado o estado bancario para propósitos de confirmar tu cuenta.',

  'Aria Label Expiry Month':
    'Seleccione el mes de vencimiento en el menú desplegable Fecha de vencimiento Obligatorio',
  'Aria Label Expiry Year':
    'Seleccione el año de vencimiento en el menú desplegable Fecha de vencimiento Obligatorio',
  Download: 'Descargar',
  PreviewStatement:
    'Información sensitiva será encriptada y custodiada en nuestros sistemas. Para confirmar su cuenta, se requiere un cheque cancelado o estado de cuenta bancario de los últimos 60 días. El cheque o estado debe reflejar su nombre para poder continuar.',
  Checking: 'Cheques',
  Savings: 'Ahorros',
  'Aria Label Transit':
    'Número de tránsito Requerido Seleccione el botón del menú desplegable Número de tránsito',
  Other: 'Otro',
  'Bank Name': 'Nombre del Banco',
  'Enter Bank name': 'Ingrese nombre del Banco',
  'Enter deposit amount. Minimum': 'Ingresa cantidad del depósito. Mínimo',
  // commercial
  'Business Account Opening': 'Comencemos con tu información de contacto.',
  'Personal/Business Information': 'Tu Información Personal/Negocio',
  'Your Account/Business': 'Tu Cuenta/Negocio',
  'We’d love to hear about you and your business!':
    '¡Nos encantaría conocer sobre ti y tu negocio!',
  BusinessContactDisclaimer:
    'Al oprimir continuar autorizas a Oriental Bank a comunicarse contigo por medio de llamada, mensaje de texto y correo electrónico.',
  'Business Legal Name': 'Nombre Legal del Negocio',
  'SURI Merchant Number': 'Número de Comerciante en SURI',
  'State Department Registry #': 'Número de Registro del Departamento de Estado',
  'Enter Business Legal Name': 'Ingresa Nombre Legal del Negocio',
  'Enter Merchant Number': 'Ingresa Número de Comerciante',
  'Enter State Department Registry': 'Ingresa Número de Registro del Departamento de Estado',
  '* SURI Merchant Number should have 11 digits':
    '* El Número de Comerciante en SURI debe tener 11 dígitos',
  '* State Department Registry # should have min 4 and max 20 digits':
    '* El número de registro del Departamento de Estado debe tener un mínimo de 4 y un máximo de 20 dígitos',
  '* Business Tax ID should have 9 digits': '* El Seguro Social Patronal debe tener 9 dígitos',
  'DBA type accounts can only be opened by the owner !':
    '¡Cuentas DBA solo pueden ser abiertas por el dueño!',

  'Entity Type': 'Tipo de Entidad',
  DBA: 'DBA',
  Corporation: 'Corporación',
  'Corporation DBA': 'Corporación DBA',
  LLC: 'LLC',
  Partnership: 'Sociedad',
  Trust: 'Fideicomiso',
  'Relationship with the Company': 'Relación con la Compañía',
  Owner: 'Dueño',
  Employee: 'Empleado',
  'Board Member Secretary': 'Secretario, Miembro de la Junta',
  'Board Member President': 'Presidente, Miembro de la Junta',
  Sales: 'Ventas',
  'Less than $ 500,000': 'Menos de $ 500,000',
  '$ 500,000 to $ 1 Million': '$ 500,000 a $ 1 Millón',
  'Over $ 5 Million': 'Sobre $ 5 Millones',
  'N/A': 'N/A',
  'Net Worth': 'Valor Neto',
  'Less than $ 50,000': 'Menos de $ 50,000',
  '$ 50,000 - $ 100,000': '$ 50,000 - $ 100,000',
  '$ 100,000 - $ 250,000': '$ 100,000 - $ 250,000',
  '$ 250,000 - $ 500,000': '$ 250,000 - $ 500,000',
  '$ 500,000 - $ 1 Million': '$ 500,000 - $ 1 Millón',
  '$ 1 Million to $ 5 Million': '$ 1 Millón a $ 5 Millones',
  'More than $ 1 Million': 'Más de $ 1 Millón',
  'Number of Employees': 'Número de Empleados',
  Married: 'Casado',
  Unmarried: 'Soltero',
  'Marital Status': 'Estado civil',
  '1 - 10': '1 - 10',
  '11 - 50': '11 - 50',
  '51 - 100': '51 - 100',
  '101 - 500': '101 - 500',
  'More than 500': 'Más de 500',

  // compliance
  'Understanding your business type is an important step to get started.':
    'Entender tu tipo de negocio es un paso importante para comenzar.',
  'Is your company involved in any of the businesses listed below?':
    '¿Tu empresa participa en alguno de los negocios enumerados a continuación?',
  'My company is one of the business listed above':
    'Mi compañía es uno de los negocios listados arriba.',
  'I certify that my company is not involved in any of these listed 19 businesses.':
    'Certifico que mi empresa no está involucrada en ninguno de estos listados 19 negocios.',

  // item
  BusinessProductModalTextOne: 'En este momento no podemos completar la solicitud.',
  BusinessProductModalTextOneCommercial: 'En este momento no podemos completar la solicitud.',

  'As per Oriental Bank policy we are currently unable to establish a relationship without an active Merchant Number.':
    'Por política de Oriental Bank, en estos momentos no podemos establecer una relación sin tener un Número de Comerciante activo.',
  'All fields are required to verify your information and to comply with federal regulations.':
    'Todos los campos son requeridos para verificar tu información y para estar en cumplimiento con las regulaciones federales.',
  'More about your business': 'Más sobre tu negocio',
  'Business Tax ID': 'Seguro Social Patronal',
  'Enter Business Tax ID': 'Ingresa Seguro Social Patronal',
  'Enter website address': 'Ingresa dirección página web',
  Website: 'Página Web',
  'Business Address': 'Dirección del Negocio',
  'Enter Business Address': 'Ingresa dirección del negocio',

  'Business Owner Address': 'Dirección Dueño del Negocio',
  'Enter Business Owner Address': 'Ingresa dirección dueño del negocio',

  'Business Name': 'Nombre del Negocio',
  'Enter Business Name': 'Ingresa nombre del negocio',

  'Business Owner Name': 'Nombre Dueño del Negocio',
  'Enter Business Owner Name': 'Ingresa nombre dueño del negocio',

  'Please enter a valid SURI Merchant Number':
    'Por favor ingresa un Número de Comerciante en SURI válido',
  'I certify that the above information is correct.':
    'Certifico que la información arriba está correcta.',
  'Detalles de certificado': 'Detalles de certificado',
  'Detalles de comerciante': 'Detalles de comerciante',
  'Detalles de la localidad': 'Detalles de la localidad',
  'Is the information for your business correct?': '¿Es correcta la información sobre tu negocio?',
  'Confirm Your Business Information': 'Confirma la información de tu negocio',
  'We have obtained your business information from SURI; please confirm this is your business:':
    'Recibimos tu información por parte de SURI; por favor confirma que éste es tu negocio:',
  WhatInfoIsIncorrect: 'Por favor déjanos saber cual información está incorrecta:',
  'Enter SURI Merchant Number': 'Ingresa tu Número de Comerciante en SURI',
  NotAbleToFetchSuriEnterAgain:
    'No hemos podido obtener los datos de tu Número de Comerciante, favor ingresar de nuevo:',
  'Do you have an active Merchant Number ?': '¿Tienes un Número de Comerciante activo?',
  'Annual Sales': 'Ventas Anuales',
  '* The ownership percentage should have a value between 1 and 100.':
    '* El porcentaje de participación debe tener un valor entre 1 y 100.',
  OwnershipPercentToolTip: 'El porcentaje de participación debe estar entre 1 y 100.',
  'Please confirm if the information obtained from SURI is correct.':
    'Por favor confirma si la información recibida de SURI es correcta.',
  'Let’s confirm your business information. We will use your SURI Merchant number for less data entry.':
    'Usaremos tu Número de Comerciante en SURI para facilitarte la entrada de datos y confirmar la información de tu negocio.',
  OwnerTrans: 'Dueño',
  'Business Owner Information': 'Informacíon Dueño del Negocio',
  'Confirm your SURI information': 'Confirma tu información de SURI',
  'Will you be a signer on this account ?': '¿Serás firmante en esta cuenta?',
  'Ownership Percentage': 'Porcentaje de Participación',
  'Is the address provided in the ID Proof your home address ?':
    '¿La dirección provista en el ID es tu dirección residencial?',

  "Private ATM's": 'ATM Privadas',
  ' Act No. 20': ' Ley No. 20',
  InternetGamblingModalText:
    'Por política de Oriental Bank, en estos momentos no podemos establecer una relación con negocios relacionados con Apuestas en Internet.',
  'Internet Gambling': 'Apuestas en Internet',
  'Fast Cash Services': 'Servicios de efectivo rápido',
  'Bulk Cash Services': 'Manejo de grandes cantidades de efectivo',
  'Is the company engaged in any of the following?':
    '¿Está la empresa involucrada en alguno de los siguientes?',
  Act20Cert: '(Subir certificación de la Ley No. 20)',
  ATMRental: '(Subir documentación de renta o compra de la ATM)',
  'Aria Label Relationship with Company':
    'Relación con la empresa Se requiere seleccionar Relación con el botón del menú desplegable de la empresa',
  'Enter business information below:': 'Ingresa la información comercial a continuación:',
  '* Private ATM document is required': '* Se requiere un documento privado de cajero automático',
  '* Act 20 Certification document is required':
    '* Se requiere el documento de certificación de la Ley 20',
  NetWorthTooltip:
    'Valor Neto es el valor total de sus activos (lo que posee) menos sus pasivos ( lo que debe) (excluye el hogar).',
  TaxBracketTooltip:
    'Porciento de contribuciones es el rango de ingresos que se grava a una tasa específica.',
  LiquidNetWorthTooltip:
    'Valor Neto Líquido: cantidad de dinero que se tiene en efectivo o equivalentes de efectivo luego de deducir sus pasivos de sus activos.',
  DGIModalText:
    'Por política de Oriental Bank, en estos momentos no  podemos establecer una relación con personas políticamente expuestas, sus familiares y sus socios cercanos.',
  DGIAdultTooltip:
    'Persona de 65 años o más o persona de 21 años o más que se cree que de manera razonable tiene un deterioro mental o físico.',
  'Are you a Specified Adult?': '¿Eres un Adulto Determinado?',
  'Do you want to provide information for a Trusted Contact?':
    '¿Deseas proveer información sobre un Contacto de Confianza?',
  'Employment Years': 'Años en el empleo',
  'Your Financial Information': 'Tu Información Financiera',
  Networth: 'Valor Neto',
  'Tax Bracket': 'Tasa contributiva',
  'Help us understand your General Investment Knowledge':
    'Ayúdanos a entender tus conocimientos generales de inversión',
  'What is your investment knowledge?': '¿Cuál es tu conocimiento general de inversiones?',
  'Do you have investment experience in Equities?':
    '¿Posees experiencia de inversiones en acciones?',
  'Do you have investment experience in Fixed Income?':
    '¿Posees experiencia de inversiones en bonos?',
  'Do you have investment experience in Mutual Funds?':
    '¿Posees experiencia de inversiones en fondos mutuos?',
  'What is your risk tolerance?': '¿Cuál es tu tolerancia al riesgo?',
  'What is your investment objective?': '¿Cuál es tu objetivo de inversión?',
  'What are your Liquidity Needs?': '¿Cuáles son tus necesidades de Liquidez?',
  'How long do you plan to hold the asset before selling it?':
    '¿Cuánto tiempo planificas mantener el activo antes de venderlo?',
  AssetSellingTooltip: 'Cuanto tiempo planifica mantener el activo antes de venderlo',
  DGITrustTooltip: `Un contacto de confianza es una persona autorizada por un inversionista para que su firma financiera se comunique con ella en circunstancias limitadas, como inquietudes sobre la actividad en la cuenta del inversionista o si la firma no ha podido comunicarse con el inversionista después de numerosos intentos.`,
  'Less than $30,000': 'Menos de $30,000',
  '$200,000 or More': '$200,000 o más',
  '10 or more': '10 o más',
  None: 'Ninguno',
  Limited: 'Limitado',
  Moderate: 'Moderado',
  Extensive: 'Amplio',
  'High Risk': 'Alto Riesgo',
  High: 'Altas',
  Medium: 'Medianas',
  Low: 'Bajo',
  'Capital Appreciation': 'Apreciación de Capital',
  Income: 'Ingreso',
  'Preservation of Capital': 'Preservación de Capital ',
  'Trading Profits': 'Realizar Ganancias ',
  'Before we can open your account there is additional information about you we are required to know.':
    'Antes de poder abrir tu cuenta, hay información adicional sobre ti que debemos conocer.',
  'Some questions we are required to ask:': 'Algunas preguntas que tenemos que realizar:',
  'Some questions Prog': 'Algunas preguntas que debemos hacer:',
  'Are you a Politically Exposed Person or are you related to a Politically Exposed Person?':
    '¿Eres una persona expuesta al ambiente político o estás relacionado con alguna persona expuesta al ambiente político?',
  'Are you a Politically Prog':
    '¿Eres una persona políticamente expuesta o estás relacionado a una persona políticamente expuesta?',
  'Full Name': 'Nombre completo',
  'Enter full Name': 'Ingrese el nombre completo',
  'Minimum deposit': 'Depósito Mínimo',
  'Minimum deposit Prog': 'Cantidad mínima de depósito',
  'Upload a Void check or Bank Statement': 'Suba un cheque cancelado o estado bancario',
  'My Biz Account': 'My Biz Account',
  'Ahorro Comercial': 'Ahorro Comercial',
  'Commercial CD': 'CD Comercial',
  'NON-PROFIT': 'SIN FINES DE LUCRO',
  'ESCROW ACCOUNT': 'ESCROW ACCOUNT',
  'Commercial Loan': 'Préstamo Comercial',
  'Commercial Credit Card': 'Tarjeta de Crédito Comercial',
  'Commercial Line of Credit': 'Línea de Crédito Comercial',
  Months: 'Meses',
  Years: 'Años',
  'Trusted Contact Information': 'Información de contacto de confianza',
  'What is your employment status?': 'Tu información de empleo',
  'What is your employment Prog': '¿Cuál es tu situación laboral?',
  '* Should have month less than 12': '* Debe tener un mes inferior a 12',
  'To comply with regulation it is important we obtain your financial information and evaluate your general investment knowledge. Please select below.':
    'Para lograr el cumplimiento con toda regulación, es importante obtener tu información financiera y evaluar tu conocimiento sobre inversiones en general. Por favor selecciona entre las siguientes opciones sugeridas.',
  'Liquid Net Worth': 'Valor Neto Líquido',
  'Employment Time Period': 'Tiempo en el Empleo',
  // edelivery
  'Use the options below to choose how you would like to receive your account information.':
    'Utiliza las opciones a continuación para escoger como prefieres recibir la información de tu cuenta.',
  'How would you like to receive your account information?':
    '¿Como te gustaría recibir la información de tu cuenta?',
  'For your convenience, account communications will be delivered to you electronically.':
    'Para tu conveniencia, recibirás toda comunicación sobre tu cuenta en formato electrónico.',
  'You will be notified by e-mail when documents become available for you to retrieve online at our website.':
    'Recibirás un correo electrónico una vez los documentos estén disponibles para descargar utilizando nuestra página electrónica.',
  'DGI Balanced Fund IRA Account Online Access':
    'Acceso en línea para Cuenta DGI Balanced fund IRA',
  'Do you have a existing user ID?': '¿Tienes usuario existente?',
  'Enter user id': 'Ingresa tu ID existente',
  'New User': 'Nuevo usuario',
  'Please provide 2 user ID options. You will receive a email confirmation of enrollment once your account setup is completed?':
    'Por favor provéenos dos opciones para tu ID de usuario. Recibirás un correo electrónico confirmando tu registro una vez tu cuenta este completada?',
  'User ID Option 1': 'ID usuario Opción 1',
  'User ID Option 2': 'ID usuario Opción 2',
  'Enter your first option': 'Ingresa tu primera opción',
  'Enter your second option': 'Ingresa tu segunda opción',
  'Taxpayer Certification for U.S. Person':
    'Certificado de contribuyente para persona estadounidense',
  'This section is not to be used by non-U.S. person':
    'Esta sección no debe ser utilizada por extranjeros no residentes y entidades extranjeras.',
  'Under penalties of perjury, I certify that:': 'Bajo pena de perjurio, certifico que:',
  '1. The number shown for the Primary Account Holder on this form is my correct Social Security Number or Taxpayer Identification Number (or I am waiting for a number to be issued to me);':
    '1. El número que aparece en el Paso 4 de este formulario es mi número correcto de Seguro Social o Número de identificación del contribuyente (o estoy esperando que se me asigne un número).',
  '2. I am not subject to backup withholding because':
    '2. No estoy sujeto a retención retroactiva debido a que',
  'If you have been notified by the IRS that you are currently subject to backup withholding because you have failed to report all interest and dividends on your tax return. For mortgage interest paid, acquisition or abandonment of secured property,cancellation of debt, contributions to an individual retirement arrangement (IRA),and generally, payments other than interest and dividends, you are not required to sign the certification, but you must provide your correct TIN.':
    'Si el IRS no le ha notificado que actualmente está sujeto a retención retroactiva por no haber incluido todos los intereses y dividendos en su declaración de impuestos. En el caso de intereses hipotecarios pagados, adquisición o abandono de una propiedad hipotecada, cancelación de una deuda, aportaciones a una cuenta individual de jubilación (IRA), y, en general, pagos que no sean intereses y dividendos, usted no tiene la obligación de firmar el certificado pero debe proporcionar su número de contribuyente (TIN) correcto.',
  'I am exempt from backup withholding or': 'estoy exento de retención retroactiva o',
  'I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or':
    'el Servicio de Rentas Internas (IRS) no me ha notificado que estoy sujeto a retención retroactiva como resultado de no declarar todos los intereses o dividendos, o',
  'the IRS has notified me that I am no longer subject to backup withholding; and':
    'el IRS me ha notificado que ya no estoy sujeto a retención retroactiva',

  'If you are an exempt payee (if you are unsure, ask us for a complete set of IRS instructions), enter your exempt payee code (if any) here:':
    'Si usted es un beneficiario exento (si no está seguro, pídanos una serie completa de instrucciones del IRS), ingrese su código de beneficiario exento (si corresponde) aquí:',

  'If you are exempt from FATCA reporting (if you are unsure, ask us for a complete set of IRS instructions), enter your exemption from FATCA reporting code (if any) here:':
    'Si está exento de informar a FATCA (si no está seguro, solicítenos las instrucciones de IRS completas), ingrese su exención del código de informe de FATCA (si corresponde) aquí:',

  '3. I am a U.S. citizen or other U.S. person and':
    '3. Soy ciudadano estadounidense u otra persona estadounidense',
  '4. The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct.':
    '4. El código FATCA ingresado en este formulario (si corresponde) que indica que estoy exento de informar a FATCA es correcto',
  'Electronic Delivery': 'Envío Electrónico',
  'You will receive all communications via postal mail.':
    'Recibirás todas tus comunicaciones mediante correo postal.',
  '* Your Work Experience can not be more than or equal to Age':
    '* Tu Experiencia Laboral no puede ser mayor o igual a la Edad',
  // new
  'Definition of a U.S. person.': 'Definición de una persona estadounidense.',
  'For federal tax purposes, you are considered a U.S. person if you are:':
    'A los propósitos de impuestos federales, se lo considera una persona estadounidense si usted es:',
  'An individual who is a U.S. citizen or U.S. resident alien,':
    'Una persona que es un ciudadano estadounidense o extranjero residente estadounidense.',
  'A partnership, corporation, company or association created or organized in the United States or under the laws of the United States,':
    'Una asociación, corporación, empresa o sociedad creada u organizada en los Estados Unidos o según las leyes de los Estados Unidos.',
  'An estate (other than a foreign estate), or':
    'Un bien inmueble (aparte de un bien inmueble extranjero).',
  'A domestic trust (as defined in Regulations section 301.7701-7)':
    'Un fideicomiso doméstico (como se define en las Regulaciones, sección 301.7701-7)',
  'Enter exempt payee code': 'Ingresa tu código de beneficiario exento',
  'User ID': 'ID de usuario',
  'Enter User ID Option 1': 'Ingresa la ID de usuario Opción 1',
  '* User ID should have min 7 and max 15 characters':
    '* El ID debe tener un mínimo de 7 y máximo de 15 caracteres',
  ConsentHelperTextDGI:
    'Antes de completar esta transacción, debes entender tus derechos y responsabilidades y proveer tu consentimiento.',
  '* FATCA code should have min 8 and max 20 characters':
    '* El número de código FATCA de Estado debe tener un mínimo de 8 y un máximo de 20 caracteres',
  '* Exempt payee code should have min 8 and max 20 characters':
    '* El número de código de beneficiario exento de Estado debe tener un mínimo de 8 y un máximo de 20 caracteres',
  'Over 50%': 'Sobre 50%',
  PreviewStatementDgi:
    'Información sensitiva será encriptada y custodiada en nuestros sistemas. Para confirmar su cuenta, se requiere un cheque cancelado o estado de cuenta bancario de los últimos 60 días. El número del cheque cancelado debe ser igual o mayor a 100. Información adicional puede ser requerida si actualmente la cuenta no estuviera a tu nombre.',
  '* User ID Option 2 should not be same as User ID Option 1':
    '* El ID usuario Opción 2 no debe ser el mismo que el ID usuario Opción 1',
  '* Invalid Account Number': '* Número de cuenta no válido',
  'Upload a Void Check or Bank Statement in order to confirm the ownership of your account':
    'Suba cheque cancelado o estado bancario para propósitos de confirmar tu cuenta.',
  'Enter FATCA reporting code': 'Ingresa tu código de informe de FATCA',
  ConsentHelperTextdgi:
    'Antes de completar esta transacción, debes entender tus derechos y responsabilidades y proveer tu consentimiento. ',
  'Your information was sent successfully. We will contact you in the next 72 hours to complete the account opening process.':
    'Tu información fue enviada exitosamente. Nos comunicaremos contigo en las próximas 72 horas para completar el proceso de apertura de cuenta.',
  '* Maximum deposit allowed is $5,000': '* Depósito máximo permitido es $5,000',
  'Tax Year': 'Año contributivo',
  FundingScreenDisclaimer:
    'Al presionar continuar autorizo a que ORIENTAL BANK debite de mi cuenta electrónicamente un cargo único por la cantidad incluida en el campo cantidad de depósito.',
  FundingScreenDisclaimerProg:
    'Al hacer clic en Continuar, autorizo a Oriental Bank a realizar un débito electrónico único por la cantidad incluida en el campo Cantidad del depósito.',
  'Aria Label Marital Status':
    'Estado civil Requerido Seleccione el botón del menú desplegable Estado civil',
  'Aria Label Tax Year':
    'Año contributivo Requerido Seleccione el botón del menú desplegable Año contributivo',
  'Aria Label Annual Income':
    'Ingreso Anual requeridos Seleccione el botón del menú desplegable Ingreso Anual',
  'Aria Label Net Worth':
    'Valor Neto Requerido Seleccione el botón del menú desplegable Valor Neto',
  'Aria Label Liquid Net Worth':
    'Valor Neto Líquido Requerido Seleccione el botón del menú desplegable Valor Neto Líquido',
  'Aria Label Tax Bracket':
    'Tasa contributiva Requerido Seleccione el botón del menú desplegable Tasa contributiva',
  'Aria Label What is your investment knowledge?':
    '¿Cuál es tu conocimiento general de inversiones? Requerido Seleccione el botón del menú desplegable ¿Cuál es tu conocimiento general de inversiones?',
  'Aria Label Do you have investment experience in Equities?':
    '¿Posees experiencia de inversiones en acciones? Requerido Seleccione el botón del menú desplegable ¿Posees experiencia de inversiones en acciones?',
  'Aria Label Do you have investment experience in Fixed Income?':
    '¿Posees experiencia de inversiones en bonos? Requerido Seleccione el botón del menú desplegable ¿Posees experiencia de inversiones en bonos?',
  'Aria Label Do you have investment experience in Mutual Funds?':
    '¿Posees experiencia de inversiones en fondos mutuos? Requerido Seleccione el botón del menú desplegable ¿Posees experiencia de inversiones en fondos mutuos?',
  'Aria Label What is your risk tolerance?':
    '¿Cuál es tu tolerancia al riesgo? Requerido Seleccione el botón del menú desplegable ¿Cuál es tu tolerancia al riesgo?',
  'Aria Label What is your investment objective?':
    '¿Cuál es tu objetivo de inversión? Requerido Seleccione el botón del menú desplegable ¿Cuál es tu objetivo de inversión?',
  'Aria Label How long do you plan to hold the asset before selling it?':
    '¿Cuánto tiempo planificas mantener el activo antes de venderlo? Requerido Seleccione el botón del menú desplegable ¿Cuánto tiempo planificas mantener el activo antes de venderlo?',
  'Aria Label What are your Liquidity Needs?':
    '¿Cuáles son tus necesidades de Liquidez? Requerido Seleccione el botón del menú desplegable ¿Cuáles son tus necesidades de Liquidez?',
  screenHeadingDgi:
    'Tu información fue enviada exitosamente. Nos comunicaremos en las próximas 72 horas para completar la aportación a tu cuenta.',
  'DGI Account number': 'Número de cuenta DGI',
  'Money Services Business (MSB’s)': 'Negocios de Servicios Monetarios (MSB’s)',
  'Bail Bond Businesses': 'Negocios de Fianzas',
  'Pre Paid Card Sale': 'Ventas de Tarjetas Pre-pagadas',
  'Foreign Exchange Market': 'Cambio de Moneda',
  'Pawn Shops': 'Casas de Empeño',
  'Marijuana related business and services as well as drug paraphernilia related business':
    'Artículos relacionados a drogas y Negocios relacionados a la Marihuana Medicinal',
  'Offshore corporations': 'Corporaciones Offshore',
  'Collection Agencies': 'Agencias de Cobro',
  'Casinos or Slot Machines': 'Casinos / Máquinas Tragamonedas',
  'Foreign Correspondent Banks': 'Bancos Corresponsales fuera de EEUU',
  'Adult Entertainment Business': 'Entretenimiento de Adultos',
  'Precious Metal Dealers': 'Dealer de Metales Preciosos',
  'Non-US Residents Aliens': 'Extranjeros no residentes en EEUU',
  'Tarot Card Reading': 'Lectura de Tarot',
  'International Banking Entities': 'Entidades Bancarias Internacionales',
  'Third Party Payment Processors':
    'Procesadores de Pagos a Terceros (Third Party Payment Processors)',
  'Gambling Businesses and Internet Gambling':
    'Negocios de Apuestas y Apuestas por Internet (Incluyendo Agencias Hípicas, Galleras)',
  'Private ATM Machines': 'Máquinas de ATM’s Privadas',
  'Credit Restoration Businesses': 'Negocios de Reparación de Crédito',
  'Employer Address line 1': 'Dirección Línea 1',
  COUNTRY_MODAL_TEXT:
    "Por política de Oriental Bank, en estos momentos solo podemos aceptar ID's de los Estado Unidos, PR, USVI.",
  MSG_VOUCHED_MODAL: 'Sube una identificación con tu selfi para continuar con la solicitud.',
  'Social Security Number Commercial': 'Seguro Social Personal',
  'Enter social security number commercial': 'Ingresa el Seguro Social',
  'Upload Business address documentation': 'Subir evidencia de dirección para el Negocio',
  'Upload Business owner address documentation':
    'Subir evidencia de dirección para el Dueño del Negocio',
  '* Business owner address document is required':
    '* Evidencia de dirección para Dueño del Negocio requerida',
  '* Business address document is required': '* Evidencia de dirección para Negocio requerida',
  'An active Merchant Number to confirm your Business Name and Business Owner Name is required.':
    'Necesitamos confirmar el Nombre del Negocio y el Nombre del Dueño del Negocio con un Número de Comerciante Activo.',
  '* Please enter a valid DGI account number': '* Por favor ingresa un número de cuenta DGI válido',
  'Merchant Registration Certificate': 'Certificado de Registro de Comerciante',
  'Merchant Number': 'Número de Comerciante',
  'Aria Label Entity Type':
    'Tipo de Entidad Requerido Seleccione el botón del menú desplegable Tipo de Entidad',
  'Aria Label Annual Sales':
    'Ventas Anuales Requerido Seleccione el botón del menú desplegable Ventas Anuales',
  'Aria Label Number of Employees':
    'Número de Empleados Requerido Seleccione el botón del menú desplegable Número de Empleados',
  'Yes, mail my debit card.': 'Sí, envíen mi tarjeta por correo',
  'No, I don’t want a debit card for this account.':
    'No, no quiero una tarjeta de débito para esta cuenta.',
  'Your account includes a Mastercard Debit Card. Do you want us to ship you the card? (Arrives in 4-6 business days)':
    'Tu cuenta incluye una tarjeta de débito Mastercard. ¿Quieres que enviemos tu tarjeta? (Llega en 4-6 días laborales)',
  'Your account includes a Mastercard debit card. Do you want the card to be shipped? (Arrives in 4-6 business days)':
    'Tu cuenta incluye una tarjeta de débito Mastercard. ¿Quieres que te enviemos tu tarjeta? (Llega en 4-6 días laborables)',
  'Upload the required documents to open your account.':
    'Sube los documentos requeridos para abrir tu cuenta.',
  'Upload the required documents.': 'Sube los documentos requeridos.',
  'Drag file here or': 'Arrastra el archivo aquí o',
  'Choose file': 'Busca el archivo',
  'PNG, JPG and PDF files are allowed to upload.': 'Se permite cargar archivos PNG, JPG y PDF.',
  'Corporate Resolution': 'Resolución Corporativa',
  'Certificate of Incorporation': 'Certificado de Incorporación',
  'Articles of Incorporation': 'Artículos de Incorporación',
  'List of Directors and Officers': 'Lista de Directores y Oficiales',
  'Commercial Registration Certificate': 'Registro de Comerciante',
  'DBA Resolution': 'Resolución DBA',
  'LLC Resolution': 'Resolución LLC',
  'Certificate of Organization': 'Certificado de Organización/Formación',
  'Operational Agreement': 'Acuerdo Operacional',
  'Deed of Trust': 'Escritura Constitutiva del Fideicomiso',
  'Certification for Trust Registry': 'Certificacion del Registro Especial del Fideicomiso',
  'Certificate of Incumbency': 'Certificado de Incumbencia',
  'The file exceeds the maximum upload size (10 Mb).':
    'El archivo excede el tamaño máximo de carga (10 Mb).',
  'The file exceeds the maximum upload size (3 Mb).':
    'El archivo excede el tamaño máximo de carga (3 Mb).',
  'Only files with the following formats are allowed: PNG, JPG, PDF.':
    'Solo se permiten archivos con los siguientes formatos: PNG, JPG, PDF.',
  'This account is not available for processing fund transfer. Please provide another account.':
    'Esta cuenta no está disponible para procesar transferencias de fondos. Por favor, seleccione otra cuenta.',
  'cancel icon': 'cancelar icono',
  Cancel: 'Cancelar',
  'As per Oriental Bank policy an active SURI Merchant number is required to open this account.':
    'En este momento no podemos proceder ya que se requiere un número de SURI activo para abrir esta cuenta.',
  Yes: 'Si',
  No: 'No',
  'Location Details': 'Detalles de la localidad',
  'Merchant Details': 'Detalles de comerciante',
  'Certificate Details': 'Detalles de certificado',
  'NAICS Codes': 'Codigos NAICS',
  'Type Of Certificate': 'Tipo de certificado',
  // OCR
  'The name provided in the application doesn’t match with the utility bill uploaded. Please provide another bill with the applicant name.':
    'El nombre en la solicitud no coincide con el nombre de la factura de servicios que ha proporcionado. Por favor, incluya otra factura a nombre del solicitante.',
  'Utility Bill': 'Factura de Servicio',
  'Select Utility Bill Type': 'Selecione el tipo de factura de servicio',
  'Supported file types: PNG, JPG, PDF.': 'Tipo de archivo compatible: PNG, JPG, PDF.',
  'Max. 10MB': 'Máx. 10MB',
  'Uploading your documents is easy': 'Subir los documentos es fácil',
  'Proceed by uploading whichever documents you have. We will send a link to your email to upload any missing documents.':
    'Puedes cargar los documentos que tengas a la mano. Te enviaremos un enlace al correo electrónico para cargar cualquier documento que falte.',
  'Your documents have been successfully uploaded. We will reach out to you in case anything else is required.':
    'Tus documentos se han cargado con éxito. Nos pondremos en contacto contigo de necesitar algo adicional.',
  'Your Invitation has been completed and information has been captured successfully. We will reach out to you in case anything else is required.':
    'Su invitación ha sido completada. Tus documentos se han cargado con éxito. Nos pondremos en contacto contigo de necesitar algo adicional.',
  'Thank you!': '¡Gracias!',
  'Uploading your documents is easy!': '¡Subir tus documentos es fácil!',
  InviCaseClosed:
    'La página a la que intentas acceder no está disponible en estos momentos. Por favor, comunícate con nuestro servicio al cliente al 787-620-7963.',
  InviInitialCallError:
    'En estos momentos no podemos continuar con el proceso debido a un error técnico. Por favor intenta más tarde.',
  InviAllDetailsDone:
    'El enlace no está disponible debido a que todos los detalles solicitados fueron sometidos.',
  'As per Oriental Bank policy we are currently unable to establish a relationship due to the business bankruptcy.':
    'Según las políticas de Oriental Bank, en estos momentos no podemos establecer una relación debido a que el negocio está en bancarrota.',
  '* Please provide an email address different from other applicants.':
    '* Por favor ofrezca una dirección de correo electrónico distinta a los otros solicitantes.',
  'We are not able to complete your application.':
    'En este momento no podemos completar la solicitud.',
  'We were unable to validate your ID.': 'No podemos validar tu ID.',
  "The selected utility bill type doesn't match with the bill uploaded. Please review the selected bill type.":
    'El tipo de factura de servicios seleccionado no coincide con la factura proporcionada. Por favor, revise el tipo de factura seleccionado.',
  'Please upload LUMA or AAA utility bill in Spanish with the customer name and address section.':
    'Cargue la factura de servicios públicos de LUMA o AAA en español con la sección de nombre y dirección del cliente.',
  'The utility bill uploaded was issued more than 60 days ago. Please upload a bill issued within 60 days.':
    'La factura de servicios proporcionada se emitió hace más de 60 días. Por favor, proporcione una factura de los últimos 60 días.',
  AddressProofGif:
    'Sube un recibo de utilidad con tu nombre y dirección dentro de los últimos 60 días',
  "Please upload LUMA or AAA utility bill with a valid date, the customer's name and the address section.":
    'Por favor cargue una factura de LUMA o AAA la cual contenga fecha, nombre del cliente y dirección válida.',
  "Please upload the first page of a LUMA utility bill with valid date, the customer's name and the address section.":
    'Por favor cargue solo la primera página de factura de LUMA la cual contenga fecha, nombre del cliente y dirección válida.',
  'Authorized Officer - Internal Employee': 'Oficial Autorizado - Empleado Interno',
  'Authorized Officer - External': 'Oficial Autorizado - Externo',
  'Corporate Title': 'Título Corporativo',
  'Select Corporate title': 'Indica el título corporativo',
  'Accounting/Finance Manager': 'Gerente de Contabilidad/Fianzas',
  'Chief Financial Officer': 'Director Financiero',
  'Chief Operating Officer': 'Director de Operaciones',
  Controller: 'Controlador/Regulador',
  'Office Manager': 'Gerente de Oficina',
  President: 'Presidente',
  Secretary: 'Secretario',
  'Specify Corporate Title': 'Indique su título corporativo',
  "Let's hear more about your business": '¡Conozcamos más sobre tu negocio!',
  'Is your business currently on bankruptcy?':
    'Actualmente, ¿el negocio se encuentra en bancarrota?',
  'Search SURI Merchant Number': 'Búsqueda de número de comerciante en SURI',
  'Search Here': 'Haga su búsqueda aquí',
  'Please enter a valid SURI Merchant Number to proceed.':
    'Por favor ingresa un número de comerciante en SURI válido.',
  'An active SURI Merchant Number is required':
    'Un número de comerciante en SURI válido es requerido',
  'The information of my business shown by the system is correct.':
    'La información sobre mi negocio mostrada por el sistema está correcta.',
  'I confirm the changes made by me are correct.':
    'Confirmo que los cambios realizados por mí están correctos.',
  Edit: 'Editar',
  'You can drag and drop to upload the location details.':
    'Arrastra y carga el documento para subir los detalles de la localidad.',
  'Drag and drop or ': 'Arrastra el archivo aquí o ',
  'Maximum: 10MB': 'Máx. 10MB',
  'Maximum: 3MB': 'Máx. 3MB',
  'Over $25,000,000': 'Sobre $25,000,000',
  'Time in business': 'Tiempo del negocio operando',
  'Select Time in business': 'Seleccione el tiempo del negocio operando',
  'Understanding your credit needs': 'Conociendo tus necesidades de crédito',
  'Credit Amount': 'Cantidad de crédito',
  'Enter Credit Amount': 'Indica la cantidad de crédito',
  'Reason for Credit Request': 'Razón para solicitar crédito',
  'Enter Reason': 'Indica la razón',
  'Do you have a collateral for the credit?': '¿Tiene un colateral o garantía para su crédito?',
  'Specify Collateral': 'Especifique el colateral',
  Specify: 'Especifique',
  'Let’s Understand your Participation in the Business':
    'Queremos entender tu participación en el negocio',
  'Do you have ownership in the business?': '¿Eres dueño en el negocio?',
  'Change Will you be a signer on this account?': '¿Serás firmante en esta cuenta?',
  'At least one additional participant needs to be added if the applicant is neither owner or signer':
    'Al menos un participante adicional necesita ser añadido si el solicitante no es dueño o firmante en la cuenta',
  'Completing your application is easy now!': '¡Completar tu solicitud es más fácil!',
  'Provide a link to additional participants by providing their contact detail and selecting their role below.':
    'Envía un enlace para los participantes adicionales, seleccionando su rol y proveyendo su información de contacto.',
  'Account Participant': 'Participante de la cuenta',
  'Select Account Participant': 'Seleccione el rol del participante',
  'By clicking below, you will navigate to an ID verification process':
    'Seleccionando abajo, serás redirigido a un proceso de verificación de identidad',
  Camera: 'Cámara',
  'Authorized Signer': 'Firmante autorizado',
  'Beneficial Owner': 'Dueño beneficiario',
  'Person With Admin Control': 'Persona con control administrativo',
  'Add Participant': 'Añadir participante',
  'You can send invite to only one person with administrative control under this application.':
    'Solo puedes enviar invitación a una persona con control administrativo para esta solicitud.',
  '(Upload ATM Rental or Purchase Documentation)':
    '(Subir documentación de renta o compra de la ATM)',
  '(Upload your ACT 20 Certification)': '(Subir certificación de la Ley No. 20)',
  'You have successfully submitted your information.':
    'Tu información ha sido enviada exitosamente.',
  SuccessProg: 'Has sometido tu solicitud con éxito.',
  'What comes next?': '¿Cuáles son los próximos pasos?',
  'will be contacting you in the next 48 business hours to understand your needs!':
    'se pondrá en contacto contigo dentro de las próximas 48 horas laborables para conocer tus necesidades.',
  'You will receive a confirmation email with your application details. Please check your email if you requested an invitation or document upload link.':
    'Recibirás una confirmación por correo electrónico con los detalles de la solicitud. Revisa tu correo si solicitaste un enlace de invitación o para subir documentos adicionales.',
  'Back to Home page': 'Regresa a página inicial',
  'View Profile': 'Ver perfil',
  // 'You have been invited as an additional participant to provide your information':
  //   'Has sido invitado como participante adicional para proveer tu información',
  'Personal Information': 'Tu Información Personal',
  'We’d love to hear more about you!': '¡Nos encantaría conocer sobre ti!',
  'We’d love Prog': '¡Nos encantaría saber más sobre ti!',
  'My mailing address and home address are the same.':
    'Mi dirección postal es igual a la residencial.',
  'Does this account belong to you?': '¿Eres dueño de esta cuenta?',
  Title: 'Título',
  'Email id': 'Correo electrónico',
  'Personal Quote': 'Cita Personal',
  'Your Home Address Information': 'Su dirección residencial',
  '0 - 12 months': '0 - 12 meses',
  '1 - 2 years': '1 - 2 años',
  '3 - 5 years': '3 - 5 años',
  '6 - 9 years': '6 - 9 años',
  '+10 years': '+10 años',
  'You have been invited as an additional participant of ':
    'Has sido invitado como participante adicional de ',
  ' to provide your information.': ' para proveer tu información.',
  Invitation: 'Invitación',
  'Document uploaded is not supported.': 'El documento cargado no es compatible.',
  'Try Again': 'Intentar nuevamente',
  'Start ID Verification': 'Iniciar Verificación ID',
  Others: 'Otros',
  '* Invalid Amount': '* Importe no válido',
  'Before we can open your account we are required to obtain your consent on the following:':
    'Antes de abrir tu cuenta, necesitamos tu consentimiento para lo siguiente:',
  'Upload Owner Address Proof': 'Sube prueba de dirección',
  'DGI Account Online Access': 'Acceso en linea para Cuenta DGI',
  idProcess: 'Haz clic en el botón para poder validar tu identidad',
  'Personal Info Text Conti': `Nuestro formulario en línea nos ayuda a conocer de ti y tus necesidades. Sube tu ID con foto y nosotros completaremos la solicitud usando tu información.`,
  'Upload your Act 22 Certification issued by State Department':
    'Incluye tu Decreto de Ley 22 emitido por el Departamento de Estado',

  'QR C': 'QR C',
  o: 'o',
  'de Internal Generation': 'de - Generación Interna',
  'Generate QR Code': 'Generar QR Code',
  'Product Category': 'Categoría del Producto',
  'Product Name': 'Nombre del Producto',
  Language: 'Idioma',
  'Commercial Customer': 'Cliente Comercial',
  '* Invalid Commercial Customer': '* Cliente Comercial Inválido',
  'Enter Customer Name': 'Ingrese el nombre del cliente',
  nameTooltip: 'El nombre incluido en este campo será enviado a FIS como el empleador del cliente',
  'Sales Advisor': 'Asesor de Venta',
  'Enter Sales Advisor': 'Ingrese nombre del asesor',
  'E-Mail Address': 'Correo Electrónico',
  'Enter E-Mail Address': 'Ingrese correo electrónico',
  'Please confirm below details': 'Por favor confirma los siguientes detalles',
  'Product Category:': 'Categoría del Producto:',
  'Product Name:': 'Nombre del Producto:',
  'Language:': 'Idioma:',
  'Commercial Customer:': 'Cliente Comercial:',
  'Sales Advisor:': 'Asesor de Venta:',
  'E-Mail Address:': 'Correo Electrónico:',
  'QR code and URL sent successfully to your registered E-Mail Address.':
    'El QR code y el enlace (URL) fueron enviados exitosamente a su correo electrónico registrado.',
  English: 'Inglés',
  Spanish: 'Español',
  '* Amount Cannot Exceed 1,000,000': '* Cantidad no puede exceder de $1,000,000',
  'Your information was sent successfully. Your account changes will be reflected in the next 24 hours.':
    'Tu información ha sido enviada exitosamente. Los cambios a su cuenta se estarán reflejando dentro de las próximas 24 horas.',
  'Your information was sent successfully. We will be sending the new account details to your email in the next 24 hours.':
    'Tu información ha sido enviada exitosamente. Dentro de las próximas 24 horas, estarás recibiendo la información de su cuenta mediante correo electrónico.',
  'Visit orientalbank.com': 'Visita orientalbank.com',
  'e-Statements': 'Estados de cuenta electrónicos',
  ' You will be able to access your account statements electronically, free of charge, when you sign up for our ':
    ' Al registrarte en nuestra Banca Online, podrás acceder a tus estados de cuenta de forma electrónica ',
  'Your Libre account includes e-Statement! You will be able to access your account statements electronically, free of charge, when you sign up for our Online':
    'Tu cuenta Libre incluye e-Statement! Podrás tener acceso a tus estados de cuentas de forma electrónica, libre de cargos, al registrarte en nuestra',
  '* Address Line 1 should have max 30 characters.':
    '* La dirección en la línea 1 debe tener un máximo de 30 carácteres.',
  '* Address Line 2 should have max 30 characters.':
    '* La dirección en la línea 2 debe tener un máximo de 30 carácteres.',
  'Aria Label Product Category':
    'Categoría del Producto Requerido Seleccione el botón del menú desplegable Categoría del Producto',
  'Your Libre account includes e-Statement! You will be able to access your account statements electronically, free of charge, when you sign up for our':
    'Tu cuenta Libre incluye e-Statement! Podrás tener acceso a tus estados de cuentas de forma electrónica, libre de cargos, al registrarte en nuestra',

  ' Online Banking.': ' y gratuita.',
  prg: ' y gratuita.',
  'Register today to avoid charges.': 'Subscríbete hoy para evitar cargos.',
  'Accounts whose primary signer is between ages 13 to 23 or 65 years of age or older, are exempt from this fee.':
    'Cuentas cuyo firmante primario se encuentre entre las edades de 13 a 23 años o de 65 años o más, están exentas de este cargo.',
  'Repeat Verification Process': 'Repita el proceso de verificación',
  'You can repeat the verification process up to 3 times in case some information has been fetched incorrectly.':
    'Podrás repetir el proceso de verificación hasta 3 veces en caso de que alguna información se haya obtenido incorrectamente',
  'Save your application': 'Guarda tu solicitud',
  'Acquisition of property or operational equipment':
    'Adquisición de propiedad o equipo operacional',
  'Business enhancements': 'Mejoras al negocio',
  'Operating capital': 'Capital operacional',
  Refinancing: 'Refinanciamiento',
  Construction: 'Construcción',
  'Business in operation purchase': 'Compra de Negocio en marcha',
  'Inventory acquisition (Recurring need)': 'Adquisición de Inventario (Necesidad recurrente)',
  'Accounts receivable financing': 'Financiamiento de cuentas por cobrar',
  'Accounts payable': 'Cuentas por pagar',
  'Online shopping': 'Compras por internet',
  'Entertainment expenses': 'Gastos de representación',
  'Cash (Saving Account)': 'Efectivo (Cuenta de Ahorros)',
  'Cash (Certificate Deposit)': 'Efectivo (Certificado de Depósito)',
  'Movable property (Existing)': 'Bienes muebles (Existente)',
  'Movable property (To be purchased)': 'Bienes muebles (Hacer adquirido)',
  'Immovable property (Commercial)': 'Bienes inmuebles (Comercial)',
  'Immovable property (Residential)': 'Bienes inmuebles (Residencial)',
  'We noticed you want to edit your address': 'Vemos que quieres modificar tu dirección',
  'What is the reason:': '¿Por qué necesitas modificar tu dirección?',
  'That is my address, but it has errors.': 'Es mi dirección, pero tiene errores.',
  'I have a new address.': 'Mi dirección residencial es otra.',
  'You have 45 days from the date your account is opened to make your first deposit.':
    'Tendrás 45 días desde la fecha de apertura de tu cuenta para realizar tu primer depósito.',
  'You will receive an email with your new account details in the next 24 to 48 hrs.':
    'Recibirás los detalles de tu nueva cuenta por correo electrónico dentro las próximas 24 a 48 horas.',
  'You will receive an email with your new account details in the next 24 to 48 business hours.':
    'Recibirás un correo electrónico con los datos de tu nueva cuenta en las próximas 24 a 48 horas laborables.',
  'We will review your application details in order to open your account.':
    'Revisaremos los detalles de tu solicitud con el fin de abrir tu cuenta.',
  ReviewProg: 'Revisaremos los detalles de tu solicitud para abrir tu cuenta.',
  'You have successfully submitted your application.':
    'Tu información ha sido enviada exitosamente.',
  'Thank You!': '¡Gracias!',
  'Save your progress': '¡Guarda tu progreso',
  'Please verify your details': 'Por favor, verifica tus datos',
  'Save & Continue': 'Guardar y continuar',
  'There is already a saved application with the given information. Please continue your saved application by':
    'Ya existe una solicitud guardada con la información facilitada. Por favor, continua tu solicitud guardada',
  'Your application process will auto save': 'Tu proceso se guardará automáticamente',
  'Your application is saved.': 'Tu solicitud ha sido guardada.',
  'You can continue your process.': 'Puedes continuar tu proceso.',
  ' clicking here ': ' haciendo clic aquí ',
  'or continue this application without saving.': 'o continua tu solicitud sin guardar.',
  modaltext: `Guardaremos el progreso de tu solicitud y tendrás 30 días para completarla. Una vez guardada, recibirás un correo electrónico con las instrucciones sobre cómo recuperar y completar tu solicitud.`,
  'Access your saved application': 'Accede a tu solicitud guardada',
  'Enter the following information to access your saved application.':
    'Introduce la siguiente información para acceder a tu solicitud.',
  'By clicking continue, you authorize Oriental Bank to contact you via phone call, text message and email.':
    'Al hacer clic en continuar, autorizas a Oriental Bank a ponerse en contacto contigo a través de llamada telefónica, mensaje de texto y correo electrónico.',
  'Continue your application': 'Continua tu solicitud',
  'No active application found.': 'No se encontró ninguna aplicación activa.',
  'The entered details will be verified with your information which has been saved from the previous application. Terms and conditions may have changed since you saved your application.':
    'Los datos introducidos se verificarán con la información guardada de la solicitud anterior. Los terminos y condiciones pueden haber cambiado desde que guardó tu solicitud.',
  'Continue your application process from where you left!':
    '¡Continúa el proceso de solicitud donde lo dejaste!',
  '* Invalid Date': '* Fecha no válida',
  'Welcome to Oriental Bank!': '¡Bienvenido a Oriental Bank!',
  'Welcome to Oriental Prog': ' ¡Gracias por elegir a Oriental!',
  'Details about your new account:': 'Detalles de tu nueva cuenta:',
  'Please write down your account number. You will need it to subscribe to Online Banking.':
    'Por favor copia y guarda este número para suscribirte a nuestra Banca Online.',
  'Routing Number': 'Número de ruta y tránsito',
  'Routing Number Prog': 'Número de ruta',
  'Opened Date': 'Fecha de apertura',
  'You are ready for the next step.': 'Ya estás listo para el siguiente paso.',
  'Yes, you can manage your bank from the palm of your hands.':
    'Sí, puedes manejar tu banco desde la palma de tu mano. ',
  ' Subscribe to the Online and Mobile Banking with only one click and start doing so now.':
    ' Suscríbete a la Banca Online y Móvil con un solo clic y comienza ya.',
  'Subscribe Now': 'Suscribete',
  'We are opening your account': 'Estamos abriendo tu cuenta',
  'This will take only few seconds, please do not close this window.':
    'Esto tomará solo unos segundos, por favor no cierres esta ventana.',
  'Application cannot be saved. There is already a saved application with the given information.':
    'Solicitud no puede ser guardada. Ya existe una solicitud guardada con los datos ingresados.',
  'Yes, you can manage your bank from the palm of your hands. Subscribe to the Online and Mobile Banking with only one click and start doing so now.':
    'Sí, puedes manejar tu banco desde la palma de tu mano. Suscríbete a la Banca Online y Móvil con un solo clic y comienza ya.',
  'Deposit, withdraw, send and receive money at your convenience.':
    'Deposita, retira, envía y recibe dinero a tu conveniencia.',
  'Manage your money wherever you want, whenever you want.':
    'Maneja tu dinero donde quieras, cuando quieras.',
  'With Online Banking and Digital Banking': 'Con la Banca Online y Banca Móvil',
  'PHOTO Deposit': 'FOTOdepósito',
  'Live Teller': 'Cajero en Vivo',
  'Automated Teller Machine': 'Cajero Automático',
  'ATH Móvil': 'ATH Móvil',
  'People Pay': 'People Pay',
  'out of 8 resend attempts remaining': 'de los 8 intentos de reenvío restantes',
  "Haven't received the access code?": '¿No has recibido el código de acceso?',
  'Resend new access code': 'Re-enviar nuevo código de acceso',
  'By clicking submit, you agree to our': 'Al seleccionar enviar, aceptas nuestros',
  'Terms of Use': ' términos de uso',
  'Privacy Policy': 'politica de privacidad',
  and: 'y la',
  'Enter the access code sent to your mobile/email below:':
    'Ingresa el código de acceso enviado a tu mobile/email aquí:',
  '* Enter your Full SSN': '* Ingrese su SSN completo',
  'Text message': 'Mensaje de texto',
  'Mobile number': 'Número de celular',
  'Phone call': 'Llamada telefónica',
  'Phone number': 'Número de teléfono',
  'Email:': 'Correo electrónico:',
  Email: 'Correo electrónico',
  Next: 'Continuar',
  'mobile number': 'el número de celular',
  'phone number': 'el número de teléfono',
  email: 'el correo electrónico',
  'for security we have to send you an unique access code. Choose how would you like to receive the access code:':
    'por seguridad tenemos que enviarte un código único de acceso.  Escoge cómo quieres recibir el código de acceso:',
  OTPadditionalInfo:
    'Nota: Los números de teléfonos y direcciones electrónicas son los que actualmente tienes en tu perfil.  Por propósitos de seguridad, tienes que seleccionar uno de los que mostramos para poder completar esta solicitud. Si esta información no está actualizada, por favor contáctanos al (PR) 787-620-0000 ó (USVI) 1-800-981-5554 para ayudarte.',
  'Please enter the following information to continue:':
    'Por favor entra la siguiente información para continuar:',
  'Industry Type': 'Tipo de Industria',
  'Select business industry type': 'Seleccionar el tipo de industria',
  'View your new account in Online Banking.': 'Vea su nueva cuenta en Banca en línea',
  'We’re sorry, we are not able to process your request at this time due to some information on your profile. Please continue as guest to open your account.':
    'Lo sentimos, en este momento no podemos procesar tu solicitud debido a cierta información en tu perfil. Por favor continúa como invitado para abrir tu cuenta.',
  'Select your Mailing Address': 'Selecciona tu dirección postal',
  'I wish to add a new mailing address': 'Deseo añadir una nueva dirección postal',
  'View your new account details in Online Banking.':
    'Ve los detalles de tu nueva cuenta en nuestra Banca Online.',
  'Go to Online Banking': 'Ir a Banca Online',
  'Access code is valid': 'OTP verificado con éxito!',
  'Sorry, with the information provided, I can’t validate your identity. Contact us at (PR) 787.620.0000, (USVI) 1.800.981.5554 to help you.':
    'Lo sentimos, con los datos ofrecidos no logro validar tu identidad. Contáctanos al (PR) 787.620.0000, (USVI) 1.800.981.5554 para ayudarte.',
  'Direct Deposit': 'Depósito Directo',
  'Make paydays even better with Direct Deposit. Sign up to receive your payroll or other deposits directly in your account.':
    'Haz de tu día de pago uno mejor con depósito directo. Regístrate para recibir tu nómina u otros depósitos directamente en tu cuenta.',
  'Yes, I’m interested.': 'Sí, me interesa.',
  'No, I’m not interested.': 'No, no me interesa.',
  '* Please enter a valid zipcode': '* Porfavor ingresa un zipcode válido',
  'Invalid format': 'Use existing translation',
  '* Please review the deposit amount entered as it is more than the available balance on this account':
    '* Por favor revisa la cantidad ingresada para el depósito ya que es mayor al balance disponible en esta cuenta',
  'Please review the deposit amount entered as it is more than the available balance on this account':
    '* Por favor revisa la cantidad ingresada para el depósito ya que es mayor al balance disponible en esta cuenta',
  '* Please enter a valid zipcode': '* Por favor ingresa un zipcode válido',
  'The maximum limit for initial deposit is $5,000.':
    'El límite máximo para el depósito inicial es de $5,000.',
  'A minimum deposit of $1,000 is required to open this account.':
    'Un depósito mínimo de $1,000 es requerido para abrir esta cuenta.',
  'feature screen desc': 'Revisa las siguientes funciones que aplican a tu cuenta.',
  '* Minimum excess limit amount is $1,000.00':
    '* La cantidad mínima del límite de exceso es de $1,000.00',
  'The account selected is dormant or inactive. Please review your selection.':
    'La cuenta seleccionada está inactiva. Por favor revisa tu selección.',
  'The account selected is Inactive. Please review the selection and proceed':
    'The account selected is Inactive. Please review the selection and proceed',

  'If you prefer your statements on paper, you will have a monthly service charge of $5.00.':
    'Si prefieres tus estados en papel, tendrás un cargo por servicio mensual de $5.00.',
  'This account requires maintaining a minimum amount of $1,000.00 in the checking section. To help you maintain this balance, we will automatically transfer from your savings account the necessary amount to maintain this limit as you make transactions.':
    'Esta cuenta requiere mantener una cantidad mínima de $1,000.00 en la cuenta de cheques. Para ayudarte a mantener este balance, transferiremos automáticamente desde tu cuenta de ahorros la cantidad necesaria para mantener este límite a medida que realizas transacciones.',
  'In order for you to earn the highest interest rate payments on your savings, we can automatically transfer the excess of the amount you want. Tell us if you prefer to activate this function along with the maximum amount and we will take care of the rest.':
    'Para que puedas obtener los pagos de intereses más altos sobre tus ahorros, podemos transferir automáticamente el exceso del monto que desees. Dinos si prefieres activar esta función junto con el monto máximo y nosotros nos encargaremos del resto.',
  'Note: We will transfer the excess of this amount from your checking to your savings section.':
    'Nota: Pasaremos el exceso de este monto de tu sección de cheque a tu sección de ahorros.',
  'Min amount $1,000.00': 'Cantidad mínima $1,000.00',
  'Excess limit amount': 'Cantidad límite excedente',
  'Excess transfer': 'Transferencia del exceso',
  'Deficit transfer': 'Transferencia deficitaria',
  'Automatic Transfers': 'Transferencias automáticas',
  'Combined statement:': 'Estado de cuenta combinado:',
  'Your account includes combined statement. You will be able to access your two statements together.':
    'Tu cuenta incluye un estado de cuenta combinado. Podrás acceder a tus dos estados de cuenta juntos.',
  Statements: 'Estados de cuenta',
  'Your initial deposit will be reflected in your ': 'Tu depósito inicial se reflejará en la ',
  'CHECKING SECTION.': 'SECCIÓN DE CHEQUE.',
  ' Remember you can transfer your money to your saving section at any time on our Online Banking app.':
    ' Recuerda que puedes transferir tu dinero a la sección de ahorros en cualquier momento desde nuestra aplicación de Banca Online.',
  'Where my initial deposit will go?': '¿A donde irá mi depósito inicial?',
  'WE RECOMMEND': 'RECOMENDAMOS',
  'Verify with Plaid': 'Verificar con Plaid',
  'It can help you control access to your data.':
    'Puede ayudarte a controlar el acceso a tus datos.',
  'Verify Now': 'Verificar ahora',
  'Upload Bank Statement': 'Sube un estado de cuenta',
  'Upload a void check or bank statement.':
    'Sube un cheque anulado o un estado de cuenta bancario.',
  'Upload Now': 'Subir ahora',
  'Supported file types: PNG, JPG, PDF. Maximum: 10MB':
    'Tipo de archivo compatible: PNG, JPG, PDF. Máx. 10MB',
  'Uploaded successfully': 'Cargado éxitosamente',
  'Upload failed.File size exceeded': 'Error al subir documento. Tamaño del archivo',
  'Upload failed. File format not supported': 'Error al subir documento. Formato del archivo',
  'Account details': 'Detalles de la cuenta:',

  'You’re all set!': '¡Ya estás listo!',
  'We have verified the details of the bank account,':
    'Hemos verificado los detalles de la cuenta bancaria,',
  ' and you’re good to go.': ' y estás listo para comenzar.',
  'Verification Failed': 'Fallo en la verificación',
  'Your account verification is failed.Don’t worry you can upload your bank statement or ':
    'La verificación de tu cuenta falló. No te preocupes, puedes subir tu estado de cuenta bancario o ',
  'Try Again': 'Volver a Intentarlo.',
  'Verification Pending': 'Verificación pendiente',
  'Your account verification is pending. We will send you an email to validate the microdeposits. However, you must complete the application so we can finalize the account verification.':
    'La verificación de tu cuenta está pendiente. Te enviaremos un correo electrónico para validar los microdepósitos. Sin embargo, debes completar la solicitud para que podamos finalizar la verificación de cuenta.',
  'Where will my initial deposit go?': '¿A donde irá mi depósito inicial?',
  'In order for you to earn the highest interest rate payments on your savings, we can automatically':
    'Para que puedas obtener los pagos de intereses más altos sobre tus ahorros, podemos transferir automáticamente',
  ' transfer the excess of the amount you want. Tell us if you prefer to activate this function along with the maximum amount and we will take care of the rest.':
    ' el exceso del monto que desees. Dinos si prefieres activar esta función junto con el monto máximo y nosotros nos encargaremos del resto.',

  'Elite Account': 'Cuenta Elite',
  'Cuenta Elite': 'Cuenta Elite',
  'Account verification failed. Please try again.':
    'Verificación de cuenta fallida. Por favor intenta nuevamente.',
  'Account verification already completed.': 'Verificación de cuenta ya completada.',
  linkExpired:
    'Enlace para verificación de cuenta expirado. Por favor contacte a nuestro servicio de atención al cliente al 787-777-7777.',
  'Entered SSN and/or Date of birth do not match with data provided in the application.':
    'El Seguro Social y/o Fecha de nacimiento ingresados no coinciden con los datos proporcionados en la solicitud.',
  'Account Type': 'Tipo de cuenta',
  'Account information': 'Información de la cuenta',
  'Please complete the account verification using Plaid or upload a check/bank statement.':
    'Por favor completa la verificación de cuenta usando Plaid, subiendo un estado de cuenta o cheque anulado.',
  'Complete account verification plaid': 'Completa la verificación de cuenta',
  'Enter the following information to complete account verification plaid.':
    'Ingresa la siguiente información para completar la verificación de tu cuenta.',
  'plaid desc text':
    'Los detalles introducidos se verificarán con la información guardada de la solicitud anterior. Es posible que los términos y condiciones hayan cambiado desde que guardó la solicitud.',
  '* Invalid Business Name': '* Nombre de negocio inválido',
  'Accounts whose primary signer is older than 65 years of age, are exempt from this fee.':
    'Las cuentas cuyo firmante principal tenga 65 años o más están exentas de esta tarifa.',
  'If you prefer your statement on paper, you will have a monthly service charge of $2.00.':
    'Si prefieres tus estados en papel, tendrás un cargo por servicio mensual de $2.00.',
  'Would you like to make an initial deposit to your account at this moment?':
    '¿Le gustaría hacer un depósito inicial en su cuenta en este momento?',
  'Initial Deposit': 'Depósito inicial',
  ' includes e-Statement!':
    ' incluye e-Statement. Podrás acceder a tus estados de cuenta de forma electrónica y gratuita cuando te registras en nuestra',
  'Your name ': 'Tu ',
  'Libre account': 'cuenta Libre',
  'Elite account': 'cuenta Elite',
  'Ahorra account': 'cuenta Ahorra',
  'Account number exceeds the maximum allowed limit':
    'El número de cuenta excede el límite máximo permitido',
  'We have verified the details of the bank account, and you’re good to go.':
    'Hemos verificado los detalles de la cuenta bancaria y estás listo para comenzar.',
  ' includes e-Statement! You will be able to access your account statements electronically, free of charge, when you sign up for our':
    ' incluye e-Statement. Podrás acceder a tus estados de cuenta de forma electrónica y gratuita cuando te registras en nuestra ',
  'We will need more information to complete this request, please continue as guest to open your account.':
    'Necesitaremos más información para completar esta solicitud, por favor continúa como invitado para poder abrir tu cuenta.',
  'Your account includes e-Statement. You will be able to access your account statements electronically and free of charge when you sign up for our':
    'Tu cuenta incluye e-Statement. Podrás acceder a tus estados de cuenta de forma electrónica y gratuita cuando te registras en nuestra',
  'Some details of your profile do not match our records. You can open your Elite account through the new customer process, or call us at 787.434.8458 for support with the process.':
    'Algunos detalles de tu perfil no coinciden con nuestros registros. Puedes abrir tu cuenta Elite a través del proceso de cliente nuevo, o llámanos al 787.434.8458 para apoyarte con el proceso.',
};
